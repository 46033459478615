import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { AiOutlineCheck } from 'react-icons/ai';

import SEO from 'components/seo';
import { ROUTES } from 'assets/data/actpsicologos-app';
import AppLayout from 'app/AppLayout/AppLayout';
import ListSimple from 'components/List/ListSimple';
import Notice from 'components/Notice/Notice';
import useStyles from './Prices.styles';

const Prices = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <AppLayout
      headerTransparent={false}
      subheader={{
        title: t('routes.prices.title'),
      }}
    >
      <SEO
        description={t('routes.prices.meta.description')}
        title={t('routes.prices.meta.title')}
      />
      <Container className={classes.root} maxWidth="lg">
        <Typography className={classes.description} variant="body1">
          {t('routes.prices.description')}
        </Typography>
        <Container maxWidth="lg">
          <Notice
            description={t('routes.prices.free.description')}
            title={t('routes.prices.free.title')}
          />
          <Grid className={classes.grid} container spacing={8}>
            <Grid className={classes.item} item sm={6} xs={12}>
              <Paper
                className={classes.paper}
                variant="outlined"
              >
                <Box className={classes.paperTitle}>
                  <StaticImage
                    alt={t('routes.prices.items.1.title')}
                    className={classes.paperTitleImg}
                    height={64}
                    src="../../assets/img/prices-1.png"
                    width={64}
                  />
                  <Typography variant="h5">{t('routes.prices.items.1.title')}</Typography>
                </Box>
                <Box className={classes.paperPrice}>
                  <Typography variant="h2">
                    {t('routes.prices.items.1.price.amount')}
                  </Typography>
                  <Typography className={classes.paperPriceGap} variant="body1">
                    /
                  </Typography>
                  <Typography className={classes.paperPriceName} variant="body1">
                    {t('routes.prices.items.1.price.name')}
                  </Typography>
                </Box>
                <Box className={classes.paperDescription}>
                  <ListSimple items={[...Array(4).keys()].map(dIndex => ({
                    icon: <AiOutlineCheck />,
                    text: t(`routes.prices.items.1.description.${dIndex + 1}`),
                  }))}
                  />
                </Box>
                <Box className={classes.paperButton}>
                  <Button color="primary" size="large" variant="contained">
                    <Link
                      state={{
                        message: t('routes.prices.items.1.contactMessage'),
                      }}
                      to={ROUTES.CONTACT}
                    >
                      {t('routes.prices.items.1.button')}
                    </Link>
                  </Button>
                </Box>
                <Box className={classes.paperBono}>
                  <Box className={classes.paperBonoText}>
                    <Typography component="span" variant="body1">
                      {t('routes.prices.items.1.bono.description')}
                    </Typography>
                    <Typography className={classes.paperBonoPrice} variant="h5">
                      {` ${t('routes.prices.items.1.bono.price')}`}
                    </Typography>
                  </Box>
                  <Box className={classes.paperButton}>
                    <Button color="secondary" variant="contained">
                      <Link
                        state={{
                          message: t('routes.prices.items.1.bono.contactMessage'),
                        }}
                        to={ROUTES.CONTACT}
                      >
                        {t('routes.prices.items.1.bono.button')}
                      </Link>
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid className={classes.item} item sm={6} xs={12}>
              <Paper
                className={classes.paper}
                variant="outlined"
              >
                <Box className={classes.paperTitle}>
                  <StaticImage
                    alt={t('routes.prices.items.2.title')}
                    className={classes.paperTitleImg}
                    height={64}
                    src="../../assets/img/prices-2.png"
                    width={64}
                  />
                  <Typography variant="h5">{t('routes.prices.items.2.title')}</Typography>
                </Box>
                <Box className={classes.paperPrice}>
                  <Typography variant="h2">
                    {t('routes.prices.items.2.price.amount')}
                  </Typography>
                  <Typography className={classes.paperPriceGap} variant="body1">
                    /
                  </Typography>
                  <Typography className={classes.paperPriceName} variant="body1">
                    {t('routes.prices.items.2.price.name')}
                  </Typography>
                </Box>
                <Box className={classes.paperDescription}>
                  <ListSimple items={[...Array(4).keys()].map(dIndex => ({
                    icon: <AiOutlineCheck />,
                    text: t(`routes.prices.items.2.description.${dIndex + 1}`),
                  }))}
                  />
                </Box>
                <Box className={classes.paperButton}>
                  <Button color="primary" size="large" variant="contained">
                    <Link
                      state={{
                        message: t('routes.prices.items.2.contactMessage'),
                      }}
                      to={ROUTES.CONTACT}
                    >
                      {t('routes.prices.items.2.button')}
                    </Link>
                  </Button>
                </Box>
                <Box className={classes.paperBono}>
                  <Box className={classes.paperBonoText}>
                    <Typography component="span" variant="body1">
                      {t('routes.prices.items.2.bono.description')}
                    </Typography>
                    <Typography className={classes.paperBonoPrice} variant="h5">
                      {` ${t('routes.prices.items.2.bono.price')}`}
                    </Typography>
                  </Box>
                  <Box className={classes.paperButton}>
                    <Button color="secondary" variant="contained">
                      <Link
                        state={{
                          message: t('routes.prices.items.2.bono.contactMessage'),
                        }}
                        to={ROUTES.CONTACT}
                      >
                        {t('routes.prices.items.2.bono.button')}
                      </Link>
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Box className={classes.cancellationPolicy}>
            <Typography variant="h4">
              {t('routes.prices.cancellationPolicy.title')}
              :
            </Typography>
            <Typography variant="body1">
              {t('routes.prices.cancellationPolicy.description')}
            </Typography>
          </Box>
        </Container>
      </Container>
    </AppLayout>
  );
};

export default Prices;
