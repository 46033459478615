/* eslint-disable no-dupe-keys */
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  button: {
    alignSelf: 'center',
  },
  cancellationPolicy: {
    '& p': {
      marginTop: theme.spacing(1),
    },
    marginTop: theme.spacing(1),
  },
  description: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3),
    },
  },
  grid: {
    padding: theme.spacing(2, 1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 2, 2),
    },
  },
  item: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    alignItems: 'center',
    color: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  paperBono: {
    color: theme.palette.secondary.dark,
    padding: theme.spacing(5, 3, 2),
    textAlign: 'center',
  },
  paperBonoPrice: {
    display: 'inline',
  },
  paperBonoText: {
    [theme.breakpoints.up('md')]: {
      minHeight: 96,
    },
  },
  paperButton: {
    padding: theme.spacing(1),
  },
  paperDescription: {
    alignItems: 'start',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      minHeight: 228,
      padding: theme.spacing(2, 0, 1),
    },
    width: '100%',
  },
  paperHighlighted: {
    '-ms-transform': 'scaleY(1.2)', /* IE 9 */
    border: `2px solid ${theme.palette.success.light}`,
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1.02)',
    },
  },
  paperHighlightedBox: {
    background: theme.palette.success.light,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 500,
    padding: theme.spacing(0.5),
    position: 'absolute',
    top: -22,
    [theme.breakpoints.up('md')]: {
      top: -28,
    },
  },
  paperPrice: {
    color: theme.palette.primary.dark,
    display: 'flex',
    padding: theme.spacing(4, 2, 1.5),
    textAlign: 'center',
  },
  paperPriceGap: {
    alignSelf: 'flex-end',
    fontSize: 24,
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(4),
  },
  paperPriceName: {
    alignSelf: 'flex-end',
  },
  paperTitle: {
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 0),
    width: '100%',
  },
  paperTitleImg: {
    marginBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 3, 15),
    },
  },
}));
